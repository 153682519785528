import { navigate } from 'gatsby'
import { mock } from 'mockjs'
import { useState, useEffect } from 'react'
import SpinLoading from './SpinLoading'
import { useMoralis } from '../hooks/moralis'
import avatar from '../images/common/avater.png'
import { NFTInfoType } from '../hooks/type'

type NFTAuthorCardProps = {
  width?: string
  height?: string
  imgHeight?: string
  NFTAuthorInfo: NFTInfoType
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const NFTAuthorCard: React.FC<NFTAuthorCardProps> = ({
  NFTAuthorInfo,
  width = '345px',
  height = '380px',
  imgHeight = '213px',
  onClick,
}) => {
  const [loading, setLoading] = useState(true)
  const { Moralis } = useMoralis()
  useEffect(() => {
    setLoading(true)
    new Promise<boolean>(resolve =>
      setTimeout(resolve, mock('@integer(1500, 5000)'), false),
    ).then(setLoading)
  }, [NFTAuthorInfo])

  return (
    <div
      className="min-h-[379px] bg-white rounded-lg overflow-hidden cursor-pointer shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] hover:shadow-[0_16px_24px_1px_rgba(0,0,0,0.1)] hover:-translate-x-1 hover:-translate-y-2 transition-all duration-300"
      style={{ width, height }}
    >
      {loading ? (
        <div className="flex w-full h-full">
          <SpinLoading className="w-auto h-auto m-auto" />
        </div>
      ) : (
        <div
          onClick={e => {
            navigate('/NFTCreatorsDetails')
            onClick?.(e)
          }}
        >
          <div className="overflow-hidden">
            <img
              src={NFTAuthorInfo?.metadata?.fileURI}
              alt="NFTImg"
              className="h-[200px] object-cover w-full"
              style={{ height: imgHeight }}
            />
          </div>
          <div className="flex flex-col items-center justify-center px-6">
            <img
              src={Moralis.User.current()?.attributes?.avatar?._url ?? avatar}
              alt="userAvatar"
              className="w-16 h-16 rounded-full border-2 border-[#fff] -translate-y-1/2"
            />
            <div className="mt-1 text-[18px] font-boldText font-bold leading-[30px] -translate-y-8">
              {NFTAuthorInfo?.metadata?.worksName}
            </div>
            <div className="mt-3 text-[#828282] text-sm -translate-y-8 text-center">
              {NFTAuthorInfo?.owner_of}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NFTAuthorCard
