import { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import tag from '../images/NFTRelated/tag.png'
import nft1 from '../images/NFTRelated/nft1.png'
import nft2 from '../images/NFTRelated/nft2.png'
import nft3 from '../images/NFTRelated/nft3.png'
import nft4 from '../images/NFTRelated/nft4.png'
import nft5 from '../images/NFTRelated/nft5.png'
import { mock } from 'mockjs'
import NFTAuthorCard from '../components/NFTAuthorCard'

const NFTCreators = () => {
  const [creatorsList] = useState(
    new Array(15).fill('').map(() =>
      mock({
        metadata: {
          'fileURI|1': [nft1, nft2, nft3, nft4, nft5],
          worksName: '@name()',
          title: '@title(2,2)',
          userName: '@name',
          desc: '@sentence()',
          collect: '@integer(0,999)',
          collectState: '@boolean',
        },
      }),
    ),
  )
  return (
    <>
      {/* TODO: New Header Component */}
      <Header className="!max-w-[1845px] xl:!max-w-[1470px] lg:!max-w-[1095px] xmd:!max-w-[720px] sm:!max-w-[350px]" />
      <div className="bg-[#F3F7FA] overflow-hidden">
        <div
          className="h-[312px] bg-slate-300 bg-cover bg-no-repeat bg-center overflow-hidden"
          style={{
            backgroundImage: `url(${creatorsList[0]?.metadata?.fileURI})`,
          }}
        >
          <div className="w-full h-full backdrop-blur-[6px] backdrop-saturate-[180%] pt-20 bg-[rgba(255,255,255,0.71)]">
            <div className="max-w-[1845px] xl:max-w-[1470px] lg:max-w-[1095px] xmd:max-w-[720px] sm:max-w-[350px] flex mx-auto items-center justify-between mt-[70px]">
              <div className="text-[34px] font-boldText font-bold leading-[52px]">
                Creators
              </div>
              <div className="flex items-center justify-between">
                <img src={tag} alt="tag" className="w-[18px] h-[18px]" />
                <div className="ml-1">Recent Hot:</div>
                <div className="ml-4">GOLD LOTTERY TICKET #38517</div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1845px] xl:max-w-[1470px] lg:max-w-[1095px] xmd:max-w-[720px] sm:max-w-[350px] h-[84px] bg-white mx-auto -translate-y-1/2 rounded-lg px-[21px] flex items-center justify-between">
          <div className="flex items-center">
            <svg
              className="w-4 h-4 animate-reverse-spin"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            <div className="ml-4 font-numberText">40,259,597 Works</div>
          </div>
          <button className="rounded-[20px] w-[122px] h-10 border border-[#BDBDBD] text-[#828282] text-[14px] leading-[16px] flex items-center justify-between px-6">
            Newest
            <svg
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>
        <div className="mt-3 gap-x-[30px] gap-y-[33px] pb-14 grid grid-cols-5 max-w-[1845px] mx-auto xl:max-w-[1470px] xl:grid-cols-4 lg:max-w-[1095px] lg:grid-cols-3 xmd:max-w-[720px] xmd:grid-cols-2 sm:max-w-[350px] sm:grid-cols-1 sm:gap-y-4">
          {creatorsList.map((x, i) => (
            <NFTAuthorCard key={i} NFTAuthorInfo={x} />
          ))}
        </div>
        <svg
          version="1.1"
          id="L4"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
          xmlSpace="preserve"
          className="w-[100px] mx-auto flex justify-center items-center"
        >
          <circle fill="#5578ba" stroke="none" cx="8" cy="50" r="8">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#5578ba" stroke="none" cx="50" cy="50" r="8">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#5578ba" stroke="none" cx="92" cy="50" r="8">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
      <Footer className="bg-[#f3f7fa]" />
    </>
  )
}

export default NFTCreators
